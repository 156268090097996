import {graphql} from 'gatsby';
import {css, cx} from 'linaria';
import * as React from 'react';
import Page, {PageProps} from '../components/Page';
import Product, {ProductData} from '../components/Product';
import Sidebar, {SidebarData} from '../components/Sidebar';

export default ({
  data: {
    products: {nodes: products},
    ...sidebarData
  },
  location,
  ...props
}: {data: HomeData} & PageProps) => {
  const {type, brand, name} = products[0].data;
  return (
    <Page title={`The Best ${type}: ${brand} ${name} – Just Pick This`} location={location} {...props}>
      <div
        className={cx(
          'centered',
          css`
            padding-top: 0;
          `,
        )}>
        {products.map(({id, data}) => (
          <Product key={id} product={data} />
        ))}
        <Sidebar data={sidebarData} pathname={location.pathname} />
      </div>
    </Page>
  );
};

type HomeData = SidebarData & {
  products: {
    nodes: {
      id: string;
      data: ProductData;
    }[];
  };
};

export const query = graphql`
  query ProductQuery($id: String!) {
    ...SidebarFragment
    products: allAirtable(filter: {table: {eq: "Products"}, id: {eq: $id}}) {
      nodes {
        id
        data {
          ...ProductFragment
        }
      }
    }
  }
`;
